<template>
  <div style="background:white">
    <van-row class="role-row" v-for="item in filteredData" v-bind:key="item.id" @click="to(item.to,item.id)">
      <van-col style="margin-top: 6%;" span="6">
        <van-image
            round
            fit="cover"
            width="70px"
            height="70px"
            :src="require('@/assets/'+item.image)"
        />
      </van-col>
      <van-col span="14" offset="1">
        <p style="font-size: 25px;margin-top: 13%;">{{ item.name }}</p>
        <p v-if="item.name1" style="font-size: 18px;margin-top: 0%">{{ item.name1 }}</p>
      </van-col>
      <van-col span="2" style="margin-top: 11%;">
        <van-icon name="arrow"/>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "WarningPanelIndex",
  data() {
    return {
      data: [
        {
          id: 't4',
          name: '合同确认',
          name1: '',
          image: 'ht.png',
          roles: ['19060', '12064', '16310', '18280', '04002','13006','17039','17140','18005','13072','16034']
        },
        {
          id: 't0', 
          name: '风险客户',
          name1:'（当月未回款提醒）',
          image: 'fxkh.png',
          roles: ['05001', '13054', '12064', '16310']
        },
        {
          id: 't1',
          name: '应收到期',
          name1: '',
          image: 'ysdq.png',
          roles: ['19060', '12064', '16310', '18280', '04002']
        },
        {
          id: 't3',
          name: 'CRM',
          name1: '',
          image: 'crm.png',
          roles: ['19060', '12064', '16310', '18280', '04002']
        },
        {
          id: 't2',
          name: '年协',
          name1: '',
          image: 'nx.png',
          roles: ['19060', '12064', '16310', '18280', '04002']
        }
      ],
    }
  },
  computed: {
    // 根据用户角色过滤显示的菜单项
    filteredData() {
      const userRole = this.getUserRole(); // 获取当前用户角色
      return this.data.filter(item => item.roles.includes(userRole));
    }
  },
  mounted() {
  },
  methods: {
    getUserRole() {
      // 从用户信息中获取角色,这里需要根据实际情况实现
      // 例如从vuex或localStorage中获取
      return this.$store.getters.userId;
    },
    to(to, id) {
      if(id=='t0'){
        this.$router.push({path: '/WarningCustomer'})
      }else if(id=='t4'){
        this.$router.push({path: '/ContractConfirm'})
      }else{
        this.$router.push({path: '/WarningPanel/' + id})
      }
    },
  }
}
</script>

<style scoped>
.role-row {
  background: #E6E6E6;
  padding: 20px 15px;
  margin: 20px 10px;
  border-radius: 12px;
}
</style>